function initFooterAnimal() {
    const footerBar = document.querySelector('#footer-bar');
    if (!footerBar) return console.error('找不到指定元素');

    const footerAnimal = document.createElement('div');
    footerAnimal.id = 'footer-animal';
    footerAnimal.innerHTML = `
        <img class="animal entered loaded" src="https://lib.bsgun.cn/2024/10/23/650426.webp"/>
    `;

    footerBar.insertAdjacentElement('beforebegin', footerAnimal);
}

document.addEventListener('DOMContentLoaded', initFooterAnimal);
document.addEventListener('pjax:success', initFooterAnimal);